namespace Page
{
    export interface GetJQueryOptions
    {
        id?: string;
        controlFor?: string;
        containerFor?: string;
        parentOptions?: GetJQueryOptions | JQuery;
        $element?: JQuery;
        index?: number | string;
    }

    export const getJQuery = (options: GetJQueryOptions | JQuery): JQuery =>
    {
        let result: JQuery;

        if (options instanceof jQuery)
        {
            result = <JQuery>options;
        }
        else
        {
            options = <GetJQueryOptions>options;

            if (options.$element)
            {
                result = options.$element;
            }
            else if (options.id)
            {
                result = getParent(options).find(`#${options.id}`);
            }
            else if (options.controlFor)
            {
                if (options.index != null)
                {
                    result = getParent(options).find(`[data-control-for=${options.controlFor}][data-index=${options.index}]`);
                }
                else
                {
                    result = getParent(options).find(`[data-control-for=${options.controlFor}]`);
                }
            }
            else if (options.containerFor)
            {
                if (options.index != null)
                {
                    result = getParent(options).find(`[data-container-for=${options.containerFor}][data-index=${options.index}]`);
                }
                else
                {
                    result = getParent(options).find(`[data-container-for=${options.containerFor}]`);
                }
            }
            else if (options.index != null)
            {
                result = getParent(options).find(`[data-index=${options.index}]`);
            }
            else
            {
                result = $();
            }
        }

        return result;

        function getParent(options: GetJQueryOptions)
        {
            let $parent: JQuery;

            if (options.parentOptions)
            {
                $parent = getJQuery(options.parentOptions);
            }
            else
            {
                $parent = $(document.body);
            }
            return $parent;
        }
    }

    export const getForm = (options: GetJQueryOptions | JQuery | HTMLFormElement): HTMLFormElement =>
    {
        let form: HTMLFormElement = null;

        if (options instanceof HTMLFormElement)
        {
            form = options;
        }
        else if (options instanceof jQuery)
        {
            options = <JQuery>options;

            if (options.is("form"))
            {
                form = <HTMLFormElement>options[0];
            }
            else
            {
                form = <HTMLFormElement>options.find("form")[0];
            }
        }
        else
        {
            form = <HTMLFormElement>getJQuery(options)[0]
        }

        return form;
    }

    export const addClickEvent = (getJQueryOptions: GetJQueryOptions | JQuery, onClick: ($element: JQuery) => void): void =>
    {
        getJQuery(getJQueryOptions).on("click", (event: JQueryEventObject) => onClick(<JQuery>$(event.currentTarget)));
    }

    export const setupFormatEvents = (onFormat: () => void): void =>
    {
        const dataFormatSelector = "[data-format]";
        const dataFormatAdded = "format-event-added";

        let $checkboxes = $(`input[type=checkbox]${dataFormatSelector}`);

        for (let index = 0; index < $checkboxes.length; index++)
        {
            let $checkbox = $($checkboxes[index]);
            if (!$checkbox.data(dataFormatAdded))
            {
                $checkbox.on("change", onFormat);
                $checkbox.data(dataFormatAdded, true);
            }
        }

        let $textboxes = $(`input[type=text]${dataFormatSelector}`);

        for (let index = 0; index < $textboxes.length; index++)
        {
            let $textbox = $($textboxes[index]);
            if (!$textbox.data(dataFormatAdded))
            {
                $textbox.on("change", onFormat);
                $textbox.data(dataFormatAdded, true);
            }
        }

        let $selects = $(`select${dataFormatSelector}`);

        for (let index = 0; index < $selects.length; index++)
        {
            let $select = $($selects[index]);
            if (!$select.data(dataFormatAdded))
            {
                $select.on("change", onFormat);
                $select.data(dataFormatAdded, true);
            }
        }
    }

    export const setVisibility = (getJQueryOptions: GetJQueryOptions | JQuery, visible: boolean): void =>
    {
        let $element = getJQuery(getJQueryOptions);
        if (visible)
        {
            $element.show();
        }
        else
        {
            $element.hide();
        }
    }

    export const checkedValue = (getJQueryOptions: GetJQueryOptions | JQuery): string =>
    {
        return <string>getJQuery(getJQueryOptions).filter(':checked').val();
    }

    export const setCheckedValue = (getJQueryOptions: GetJQueryOptions | JQuery, value: string): void =>
    {
        const $elements = getJQuery(getJQueryOptions);

        $elements.forEach(
            ($element: JQuery) =>
            {
                const elementValue = $element.getString();
                const elementIsChecked = $element.isChecked();
                if (elementValue != value && elementIsChecked)
                {
                    $element.prop('checked', false);
                }
                else if (elementValue == value && !elementIsChecked)
                {
                    $element.prop('checked', true);
                }
            }
        );
    }

    export const isChecked = (getJQueryOptions: GetJQueryOptions | JQuery): boolean =>
    {
        return getJQuery(getJQueryOptions).is(":checked");
    }

    export const getAction = (getJQueryOptions: GetJQueryOptions | JQuery, type?: string): string =>
    {
        let $element = getJQuery(getJQueryOptions);
        let result: string = "";
        if (type != null && type != "")
        {
            result = $element.data(`action-${type}`);
        }
        else
        {
            result = $element.data('action');
        }
        return result;
    }

    export const getFormData = (forms: HTMLFormElement[]): FormData =>
    {
        let formData = new FormData();
        forms.forEach((form) =>
        {
            $(form).serializeArray().forEach((item) =>
            {
                formData.append(item.name, item.value);
            }
            );
        });

        return formData;
    }

    export const forEach = (getJQueryOptions: GetJQueryOptions | JQuery, each: ($element: JQuery) => void): void =>
    {
        const $elementList = getJQuery(getJQueryOptions);

        $elementList.each((_: number, element: Element) => each($(element)));
    }
}

$.fn.extend(
    {
        getJQuery(options: Page.GetJQueryOptions): JQuery
        {
            options.parentOptions = <JQuery>this;
            return getJQuery(options);
        },

        addClickEvent(onClick: ($element: JQuery) => void): void
        {
            Page.addClickEvent(<JQuery>this, onClick);
        },

        getAction(type?: string): string
        {
            return Page.getAction(<JQuery>this, type);
        },

        checkedValue(): string
        {
            return Page.checkedValue(<JQuery>this);
        },

        setCheckedValue(value: string): void
        {
            Page.setCheckedValue(<JQuery>this, value);
        },

        isChecked(): boolean
        {
            return Page.isChecked(<JQuery>this);
        },

        setVisibility(visible: boolean): void
        {
            Page.setVisibility(<JQuery>this, visible);
        },

        getForm(options?: Page.GetJQueryOptions): HTMLFormElement
        {
            let form: HTMLFormElement = null;

            if (options == null)
            {
                form = Page.getForm(<JQuery>this);
            }
            else
            {
                options.parentOptions = <JQuery>this;
                form = Page.getForm(options);
            }

            return form;
        },

        getString(): string
        {
            return <string>(<JQuery>this).val();
        },

        getNumber(): number
        {
            let result: number = null;
            let value = (<JQuery>this).val();
            if (value != null && value != '')
            {
                result = <number>value;
            }
            return result;
        },

        getDate(): Date
        {
            let result: Date = null;
            let value = <string>(<JQuery>this).val();
            if (value != null && value != '')
            {
                result = new Date(value);
            }
            return result;
        },

        getBoolean(): boolean
        {
            let result: boolean = false;
            let $element = (<JQuery>this);
            let value: string = null;
            if ($element.is(":checkbox"))
            {
                value = Page.checkedValue($element);
            }
            else
            {
                value = $element.getString();
            }
            if (value == "true" || value == "True")
            {
                result = true;
            }
            return result;
        },

        getNullableBoolean(): boolean | null
        {
            let result: boolean | null = null;
            let $element = (<JQuery>this);
            let value: string = null;
            if ($element.is(":checkbox"))
            {
                value = Page.checkedValue($element);
            }
            else
            {
                value = $element.getString();
            }
            if (value == "true" || value == "True")
            {
                result = true;
            }
            else if (value == "false" || value == "False")
            {
                result = false;
            }
            return result;
        },

        isEmpty(): boolean
        {
            const $element = (<JQuery>this);

            const value = $element.val();

            return value == null || value == '';
        },

        forEach(each: ($element: JQuery) => void): void
        {
            const $element = (<JQuery>this);
            Page.forEach($element, each);
        }
    }
);

const getJQuery = Page.getJQuery;